<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/coach/coachpool' }">教练列表</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <el-tabs v-model="activeName">
                <el-tab-pane v-if="is_auth('coach.coachpool.getinfo')" label="基本信息" name="info">
                    <Info/>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Info from './page/Info'

export default {
    components: {
        Info,
    },
    data() {
        return {
            activeName: 'info'
        }
    },
    // 创建
    created() {

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
